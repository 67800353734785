import React from "react";
import Icon from "@ant-design/icons";

const CkContactSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      d="M7.14508 19.0995C6.70562 19.1047 6.26944 19.0232 5.86154 18.8596C5.45364 18.696 5.08205 18.4535 4.76805 18.146C4.45405 17.8385 4.20382 17.4721 4.03171 17.0677C3.8596 16.6633 3.76899 16.2289 3.76508 15.7895V14.4995C3.76771 13.6224 4.11729 12.782 4.73747 12.1618C5.35764 11.5417 6.19802 11.1921 7.07508 11.1895H7.14508C7.28299 11.1895 7.41525 11.2442 7.51277 11.3418C7.61029 11.4393 7.66508 11.5715 7.66508 11.7095V18.5795C7.66508 18.7174 7.61029 18.8496 7.51277 18.9471C7.41525 19.0447 7.28299 19.0995 7.14508 19.0995ZM6.63508 12.2695C6.11788 12.3712 5.6519 12.649 5.31643 13.0555C4.98096 13.4621 4.79673 13.9723 4.79508 14.4995V15.7895C4.80132 16.3131 4.98764 16.8187 5.32272 17.2211C5.6578 17.6236 6.12122 17.8984 6.63508 17.9995V12.2695Z"
      fill="currentColor"
    />
    <path
      d="M26.805 19.0997H26.745C26.6071 19.0997 26.4749 19.0449 26.3773 18.9474C26.2798 18.8499 26.225 18.7176 26.225 18.5797V11.7097C26.225 11.5718 26.2798 11.4395 26.3773 11.342C26.4749 11.2445 26.6071 11.1897 26.745 11.1897C27.1845 11.1844 27.6207 11.2659 28.0286 11.4295C28.4365 11.5931 28.8081 11.8356 29.1221 12.1431C29.4361 12.4506 29.6863 12.8171 29.8584 13.2215C30.0305 13.6258 30.1211 14.0602 30.125 14.4997V15.7897C30.1224 16.6685 29.7714 17.5104 29.1491 18.1308C28.5268 18.7513 27.6838 19.0997 26.805 19.0997ZM27.255 12.2697V17.9997C27.7713 17.8977 28.236 17.6195 28.5698 17.2128C28.9036 16.806 29.0857 16.2959 29.085 15.7697V14.4997C29.0857 13.9735 28.9036 13.4634 28.5698 13.0566C28.236 12.6499 27.7713 12.3717 27.255 12.2697Z"
      fill="currentColor"
    />
    <path
      d="M17.655 20.3008H16.215C15.6627 20.3008 15.215 20.7485 15.215 21.3008V21.5908C15.215 22.1431 15.6627 22.5908 16.215 22.5908H17.655C18.2073 22.5908 18.655 22.1431 18.655 21.5908V21.3008C18.655 20.7485 18.2073 20.3008 17.655 20.3008Z"
      fill="currentColor"
    />
    <path
      d="M24.445 22.0007H17.285C17.2076 22.0228 17.1262 22.0267 17.047 22.0119C16.9679 21.9972 16.8933 21.9643 16.829 21.9159C16.7648 21.8674 16.7127 21.8047 16.6768 21.7326C16.6409 21.6606 16.6222 21.5812 16.6222 21.5007C16.6222 21.4202 16.6409 21.3409 16.6768 21.2688C16.7127 21.1968 16.7648 21.1341 16.829 21.0856C16.8933 21.0371 16.9679 21.0042 17.047 20.9895C17.1262 20.9748 17.2076 20.9786 17.285 21.0007H24.445C26.195 21.0007 26.225 18.7507 26.225 18.6507C26.225 18.5829 26.2385 18.5158 26.2648 18.4532C26.291 18.3907 26.3295 18.334 26.3779 18.2865C26.4263 18.239 26.4837 18.2017 26.5468 18.1766C26.6098 18.1516 26.6772 18.1394 26.745 18.1407C26.8803 18.1407 27.01 18.1945 27.1056 18.2901C27.2013 18.3857 27.255 18.5155 27.255 18.6507C27.255 19.7507 26.675 22.0007 24.445 22.0007Z"
      fill="currentColor"
    />
    <path
      d="M16.925 30.2509C16.1046 30.2482 15.3187 29.9205 14.7395 29.3394C14.1603 28.7583 13.835 27.9713 13.835 27.1509V24.3509C13.8343 24.2717 13.8519 24.1934 13.8866 24.1223C13.9214 24.0511 13.9722 23.989 14.035 23.9409C14.1017 23.8957 14.1775 23.8659 14.2571 23.8538C14.3367 23.8417 14.418 23.8475 14.495 23.8709C15.2246 24.1256 15.9923 24.2541 16.765 24.2509H17.095C17.8677 24.2532 18.6352 24.1247 19.365 23.8709C19.4405 23.8477 19.5202 23.8419 19.5981 23.854C19.6761 23.8661 19.7503 23.8958 19.815 23.9409C19.8792 23.9885 19.9315 24.0503 19.9679 24.1214C20.0043 24.1925 20.0239 24.271 20.025 24.3509V27.1509C20.025 27.9731 19.6984 28.7616 19.1171 29.3429C18.5357 29.9243 17.7472 30.2509 16.925 30.2509ZM14.845 25.0009V27.1309C14.8252 27.4182 14.8646 27.7065 14.9609 27.9779C15.0572 28.2493 15.2083 28.498 15.4048 28.7085C15.6013 28.9191 15.8389 29.0869 16.1031 29.2017C16.3672 29.3165 16.6521 29.3757 16.94 29.3757C17.228 29.3757 17.5129 29.3165 17.777 29.2017C18.0412 29.0869 18.2788 28.9191 18.4753 28.7085C18.6718 28.498 18.8229 28.2493 18.9192 27.9779C19.0155 27.7065 19.0549 27.4182 19.035 27.1309V25.0009C18.4027 25.1518 17.7551 25.229 17.105 25.2309H16.775C16.125 25.2296 15.4772 25.1524 14.845 25.0009Z"
      fill="currentColor"
    />
    <path
      d="M17.1851 25.2508H16.6951C14.5733 25.2508 12.5385 24.4079 11.0382 22.9076C9.53792 21.4073 8.69507 19.3725 8.69507 17.2508V12.8008C8.69507 10.679 9.53792 8.64422 11.0382 7.14393C12.5385 5.64364 14.5733 4.80078 16.6951 4.80078H17.1851C19.3068 4.80078 21.3416 5.64364 22.8419 7.14393C24.3422 8.64422 25.1851 10.679 25.1851 12.8008V17.2408C25.1864 18.2922 24.9804 19.3336 24.579 20.3053C24.1775 21.2771 23.5885 22.1602 22.8455 22.9041C22.1025 23.648 21.2201 24.2382 20.2488 24.6409C19.2776 25.0435 18.2365 25.2508 17.1851 25.2508ZM16.6951 5.79078C14.8386 5.79078 13.0581 6.52828 11.7453 7.84103C10.4326 9.15379 9.69507 10.9343 9.69507 12.7908V17.2308C9.69507 19.0873 10.4326 20.8678 11.7453 22.1805C13.0581 23.4933 14.8386 24.2308 16.6951 24.2308H17.1851C19.0416 24.2308 20.8221 23.4933 22.1348 22.1805C23.4476 20.8678 24.1851 19.0873 24.1851 17.2308V12.8008C24.1851 10.9443 23.4476 9.16379 22.1348 7.85103C20.8221 6.53828 19.0416 5.80078 17.1851 5.80078L16.6951 5.79078Z"
      fill="currentColor"
    />
    <path
      d="M26.735 13.1292C26.6024 13.1292 26.4752 13.0765 26.3815 12.9828C26.2877 12.889 26.235 12.7618 26.235 12.6292C26.235 10.2608 25.2942 7.98946 23.6195 6.31476C21.9448 4.64006 19.6734 3.69922 17.305 3.69922H16.565C14.1984 3.70187 11.9296 4.64388 10.257 6.31829C8.58448 7.9927 7.64502 10.2626 7.64502 12.6292C7.64246 12.761 7.58896 12.8867 7.49574 12.9799C7.40252 13.0732 7.27683 13.1267 7.14502 13.1292C7.01241 13.1292 6.88523 13.0765 6.79147 12.9828C6.6977 12.889 6.64502 12.7618 6.64502 12.6292C6.64502 9.99735 7.68984 7.47313 9.54991 5.61118C11.41 3.74923 13.9332 2.70187 16.565 2.69922H17.305C19.9378 2.70187 22.462 3.74891 24.3237 5.61057C26.1853 7.47223 27.2324 9.99643 27.235 12.6292C27.235 12.7618 27.1823 12.889 27.0886 12.9828C26.9948 13.0765 26.8676 13.1292 26.735 13.1292Z"
      fill="currentColor"
    />
  </svg>
);

const CkContactIcon = (props: any) => (
  <Icon component={CkContactSvg} {...props} />
);

export default CkContactIcon;
