import React from "react";
import Icon from "@ant-design/icons";

const CkBlogSvg = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Blog-Icon" clipPath="url(#clip0_1932_38108)">
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4351 18.6199H3.06506V17.4199H16.4351V18.6199Z"
        fill="currentColor"
      />
      <path
        id="Vector (Stroke)_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4351 23.3699H3.06506V22.1699H16.4351V23.3699Z"
        fill="currentColor"
      />
      <path
        id="Vector (Stroke)_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.40002 2.40039H27.6V12.6004H3.40002V2.40039ZM4.60002 3.60039V11.4004H26.4V3.60039H4.60002Z"
        fill="currentColor"
      />
      <path
        id="Vector (Stroke)_4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8196 15.1568C22.0247 15.0509 22.2723 15.0719 22.4565 15.2109L32.6465 22.9009C32.8376 23.0451 32.9246 23.2891 32.868 23.5217C32.8115 23.7543 32.622 23.931 32.386 23.9713L29.1036 24.5317L31.4552 28.9689C31.61 29.2609 31.4995 29.623 31.208 29.7789L28.628 31.1589C28.4875 31.2341 28.3227 31.2503 28.1702 31.2038C28.0177 31.1573 27.8899 31.0521 27.8151 30.9112L25.4524 26.4618L22.8959 28.9775C22.7258 29.1448 22.4728 29.1955 22.2514 29.1066C22.03 29.0177 21.8824 28.806 21.8753 28.5676L21.4953 15.7076C21.4885 15.4769 21.6146 15.2627 21.8196 15.1568ZM22.7317 16.922L23.0342 27.1579L25.1842 25.0422C25.3192 24.9093 25.5091 24.848 25.6963 24.8768C25.8835 24.9056 26.0461 25.0212 26.135 25.1885L28.5927 29.8169L30.115 29.0027L27.6549 24.3608C27.5648 24.1907 27.5617 23.9877 27.6467 23.815C27.7316 23.6423 27.8943 23.5208 28.0841 23.4884L30.815 23.0221L22.7317 16.922Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1932_38108">
        <rect
          width="32"
          height="32"
          fill="currentColor"
          transform="translate(0.925049)"
        />
      </clipPath>
    </defs>
  </svg>
);

const CkBlogIcon = (props: any) => (
  <Icon component={CkBlogSvg} {...props} />
);

export default CkBlogIcon;
