import React from "react";
import Icon from "@ant-design/icons";

const CkFaqSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30" fill="none">
  <g clip-path="url(#clip0_1932_38097)">
    <path d="M27.359 0C25.491 0 23.8495 1.35849 23.4533 3.22642C23.3967 3.50943 23.6231 3.84906 23.9061 3.90566C24.1892 3.96226 24.5288 3.73585 24.5854 3.45283C24.8118 2.15094 26.0005 1.18868 27.359 1.18868C28.9439 1.18868 30.1892 2.43396 30.1892 4.01887C30.1892 5.03774 29.5665 5.71698 28.8307 6.50943C27.925 7.5283 26.8495 8.66038 26.8495 10.6415C26.8495 10.9811 27.076 11.2075 27.4156 11.2075C27.7552 11.2075 27.9816 10.9811 27.9816 10.6415C27.9816 9.11321 28.7741 8.20755 29.6797 7.30189C30.5288 6.39623 31.3778 5.49057 31.3778 4.01887C31.3212 1.75472 29.5665 0 27.359 0Z" fill="white"/>
    <path d="M27.4156 12.4531C26.9628 12.4531 26.5665 12.8494 26.5665 13.3022C26.5665 13.755 26.9628 14.1512 27.4156 14.1512C27.8684 14.1512 28.2646 13.755 28.2646 13.3022C28.2646 12.7927 27.8684 12.4531 27.4156 12.4531Z" fill="white"/>
    <path d="M17.1703 22.2449C15.8684 22.2449 14.793 21.1695 14.793 19.8676V17.8865C14.793 17.7166 14.9062 17.5468 15.0194 17.4336C15.1326 17.3204 15.359 17.3204 15.5288 17.377C16.0383 17.5468 16.5477 17.6034 17.0571 17.6034H17.2835C17.793 17.6034 18.3024 17.4902 18.8118 17.377C18.9816 17.3204 19.1515 17.3204 19.3213 17.4336C19.4911 17.5468 19.5477 17.7166 19.5477 17.8865V19.8676C19.5477 21.1695 18.4722 22.2449 17.1703 22.2449ZM15.925 18.6223V19.8676C15.925 20.5468 16.4911 21.1129 17.1703 21.1129C17.8496 21.1129 18.4156 20.5468 18.4156 19.8676V18.6223C18.0194 18.6789 17.6798 18.7355 17.2835 18.7355H17.0571C16.6609 18.7355 16.2647 18.6789 15.925 18.6223Z" fill="white"/>
    <path d="M17.1703 18.7365C13.8307 18.7365 11.1703 16.0195 11.1703 12.7365V9.96289C11.1703 6.62327 13.8307 3.96289 17.1703 3.96289C20.5099 3.96289 23.1703 6.67987 23.1703 9.96289V12.7365C23.1703 16.0195 20.5099 18.7365 17.1703 18.7365ZM17.1703 5.09497C14.4533 5.09497 12.3024 7.30251 12.3024 9.96289V12.7365C12.3024 15.4535 14.5099 17.6044 17.1703 17.6044C19.8307 17.6044 22.0382 15.3969 22.0382 12.7365V9.96289C22.0382 7.24591 19.8873 5.09497 17.1703 5.09497Z" fill="white"/>
    <path d="M23.2835 29.9994C22.9439 29.9994 22.7175 29.773 22.7175 29.4333V26.6031C22.7175 26.2635 22.9439 26.0371 23.2835 26.0371C23.6231 26.0371 23.8495 26.2635 23.8495 26.6031V29.4333C23.8495 29.773 23.6231 29.9994 23.2835 29.9994Z" fill="white"/>
    <path d="M28.208 29.9997H10.9439C10.7741 29.9997 10.6043 29.9431 10.4911 29.7733L1.03823 17.5469C0.925026 17.377 0.868422 17.094 0.98163 16.9242L6.86842 6.67893C7.26465 5.99968 7.94389 5.49025 8.67974 5.26383C9.4722 5.03742 10.2646 5.15063 10.9439 5.54685C11.6231 5.94308 12.1326 6.62232 12.359 7.35817C12.5854 8.15063 12.4722 8.94308 12.076 9.62232L8.0005 16.6978L10.7741 20.3204L15.1892 19.4148C15.359 19.3582 15.5288 19.4148 15.642 19.528C15.7552 19.6412 15.8684 19.811 15.8684 19.9808C15.8684 20.6035 16.3779 21.1129 17.0005 21.1129H17.3401C17.9628 21.1129 18.4722 20.6035 18.4722 19.9808C18.4722 19.811 18.5288 19.6412 18.6986 19.528C18.8118 19.4148 18.9816 19.3582 19.1514 19.4148L26.2835 20.9431C27.6986 21.2827 28.7175 22.5846 28.7175 24.0563V29.4336C28.7741 29.7733 28.4911 29.9997 28.208 29.9997ZM11.2269 28.8676H27.642V24.0563C27.642 23.094 27.0194 22.3016 26.0571 22.0752L19.4345 20.7167C19.1514 21.6223 18.3024 22.3016 17.2835 22.3016H17.0005C15.9816 22.3016 15.1326 21.6223 14.8496 20.7167L10.6609 21.5657C10.4345 21.6223 10.208 21.5091 10.0948 21.3393L6.86842 17.1506C6.75521 16.9808 6.69861 16.6978 6.81182 16.528L11.1137 9.11289C11.3401 8.66006 11.4533 8.15063 11.2835 7.6978C11.1703 7.18836 10.8307 6.79214 10.4345 6.56572C9.98163 6.33931 9.4722 6.2261 9.01937 6.39591C8.50993 6.50912 8.1137 6.84874 7.88729 7.24497L2.17031 17.1506L11.2269 28.8676Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_1932_38097">
      <rect width="30.3962" height="30" fill="white" transform="translate(0.925049)"/>
    </clipPath>
  </defs>
</svg>
);

const CkFaqIcon = (props: any) => (
  <Icon component={CkFaqSvg} {...props} />
);

export default CkFaqIcon;
