import React from "react";
import Icon from "@ant-design/icons";

const CkClockSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 6C11 8.76167 8.76167 11 6 11C3.23833 11 1 8.76167 1 6C1 3.23833 3.23833 1 6 1C8.76167 1 11 3.23833 11 6V6Z" stroke="currentColor"/>
    <path d="M6 3.22223V6.00001L7.66667 7.66667" stroke="currentColor"/>
  </svg>
);

const CkClockIcon = (props: any) => (
  <Icon component={CkClockSvg} {...props} />
);

export default CkClockIcon;
